<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
  metaInfo: {
    meta: [
      { name: 'robots', content: process.env.NUXT_ENV_NOFOLLOW || 'noindex, nofollow' },
    ]
  },
}
</script>

<style>
html {
  scroll-behavior: smooth;
}

#app p {
  @apply mb-6;
}

#app p:last-child {
  @apply mb-0;
}

#app .footer {
  @apply sm:text-left text-right;
}

#app .center {
  @apply text-center;
}

#app {
  font-family: Roboto, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @apply text-base text-black font-light leading-relaxed lg:text-lg;
}

.container {
  @apply mx-auto;
}
</style>
